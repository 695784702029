import { getAuthorizationStatus } from '../authorization-status/capability'
import { HELP_ACTION } from '../config'
import { type Trello } from '../types/trello'

export async function getOnEnable (
  t: Trello.PowerUp.IFrame,
): Promise<void> {

  const { authorized } = await getAuthorizationStatus(t)

  return t.modal({
    title: `Workspace Reports`,
    url: "./onboarding.html",
    height: !authorized ? 400 : 600,
    fullscreen: authorized ? true : false,
    actions: [HELP_ACTION]
  })
}
