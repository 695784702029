import { type Column } from './types/power-up'
import { Trello } from './types/trello'

export const PUP_DISPLAY_NAME = 'Workspace Reports'
export const PUP_ID = '1'

export const labelColors = {
    yellow: 'bg-[#F5CD47] hover:bg-[#E2B203] text-[#533F04]',
    yellow_light: "bg-[#FFF7D6] hover:bg-[#F8E6A0] text-[#7F5F01]",
    yellow_dark: 'bg-[#946F00] hover:bg-[#7F5F01] text-white',
    red: 'bg-[#F87168] hover:bg-[#FD9891] text-[#5D1F1A]',
    red_light: "bg-[#FFECEB] hover:bg-[#FFD5D2] text-[#AE2E24]",
    red_dark: 'bg-[#C9372C] hover:bg=[#AE2E24] text-white',
    orange: 'bg-[#FEA362] hover:bg-[#FEC195] text=[#702E00]',
    orange_dark: 'bg-[#C25100] hover:bg-[#A54800] text-white',
    orange_light: "bg=[#FEDEC8] hover-bg-[#FEC195] text-[#A54800]",
    blue: 'bg-[#579DFF] hover:bg-[#85B8FF] text-[#09326C]',
    blue_dark: 'bg-[#0C66E4] hover:bg-[#0055CC] text-white',
    blue_light: "bg-[#CCE0FF] hover:bg-[#85B8FF] text-[#0055CC]",
    purple: 'bg-[#9F8FEF] hover:bg-[#B8ACF6] text-[#x352C63]',
    purple_dark: 'bg-[#6E5DC6] hover:bg-[#5E4DB2] text-white',
    purple_light: "bg-[#DFD8FD] hover:bg-[#B8ACF6] text-[#5E4DB2]",
    sky: 'bg-[#6CC3E0] hover:bg-[#9DD9EE] text-[#164555]',
    sky_dark: "bg-[#227D9B] hover:bg-[#206A83] text-white",
    sky_light: "bg-[#C6EDFB] hover:bg-[#9DD9EE] text=[#206A83]",
    lime: 'bg-[#94C748] hover:bg-[#B3DF72] text-[#37471F]',
    lime_dark: "bg-[#5B7F24] hover:bg-[#4C6B1F] text-white",
    lime_light: "bg-[#D3F1A7] hover:bg-[#B3DF72] text-[#4C6B1F]",
    pink: 'bg-[#FDD0EC] hover:bg-[#F797D2] text-[#50253F]',
    pink_dark: "bg-[#AE4787] hover:bg-[#943D73] text-white",
    pink_light: "bg-[#FDD0EC] hover:bg-[#F797D2] text-[#943D73]",
    black: 'bg-[#8590A2] hover:bg-[#B3B9C4] text-[#091E42]',
    black_dark: "bg-[#626F86] hover:bg-[#44546F] text-white",
    black_light: "bg-[#DCDFE4] hover:bg-[#B3B9C4] text-[#44546F]",
    green: 'bg-[#4BCE97] hover:bg-[#7EE2B8] text-[#164B35]',
    green_dark: 'bg-[#1F845A] hover:bg-[#216E4E] text-white',
    green_light: 'bg-[#BAF3DB] hover:bg-[#7EE2B8] text-[#216E4E]',
}

export const columns: Column[] = [
  {
      id: 2,
      sortOrder: 1,
      isSortable: true,
      name: "Id",
      filterName: "id",
      fieldName: "card.idShort"
  },
  {
      id: 3,
        sortOrder: 2,
      isSortable: true,
      name: "Card",
      filterName: "Card",
      fieldName: "card.name"
  },
  {
      id: 4,
      sortOrder: 3,
      isSortable: true,
      name: "Board",
      filterName: "Board",
      fieldName: "boardName"
  },
  {
      id: 5,
        sortOrder: 4,
      isSortable: true,
      name: "List",
      filterName: "List",
      fieldName: "inList"
  },
  {
      id: 6,
        sortOrder: 5,
      isSortable: true,
      name: "Start",
      filterName: "Start",
      fieldName: "card.start"
  },
  {
      id: 7,
        sortOrder: 6,
      isSortable: true,
      name: "Due",
      filterName: "Due",
      fieldName: "card.due"
  },
  {
      id: 8,
        sortOrder: 7,
      isSortable: true,
      name: "Duration",
      filterName: "Duration",
      fieldName: "duration"
  },
  {
      id: 11,
        sortOrder: 10,
      isSortable: true,
      name: "Members",
      filterName: "Members",
      fieldName: "members"
  },
  {
    id: 14,
    sortOrder: 11,
    isSortable: true,
    name: "Labels",
    filterName: "Labels",
    fieldName: "card.labels"
},
  {
      id: 12,
        sortOrder: 12,
      isSortable: true,
      name: "Last activity",
      filterName: "Last activity",
      fieldName: "card.dateLastActivity"
  },
  {
      id: 13,
        sortOrder: 13,
      isSortable: true,
      name: "Card link",
      filterName: "Card link",
      fieldName: "card.shortLink"
  },
]

type ColumnsMap = Map<number, Column>

export const columnsMap = columns.reduce<ColumnsMap>((acc: ColumnsMap, column) => {
  acc.set(column.id, column)
  return acc
}, new Map())

export const HELP_ACTION = {
  icon: "https://trello.bluedashersoftware.com/static/help.svg",
  alt: "help",
  position: "right" as "right" | "left",
  callback: (t: Trello.PowerUp.IFrame) => {
    return t.popup({
      title: "Help Center",
      url: "./help.html",
      height: 300
    })
  }
}
