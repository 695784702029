export const PRICE = 3

export const TRELLO_TOKEN_KEY_MAP: Record<string, string> = {
  "1": "workspace-reports-trello-token",
  "3": "tags-trello-token",
  "5": "mermaid-gantt-trello-token",
  "6": "heatmap-trello-token",
  "7": "import-trello-token",
  "8": "quickbooks-trello-token"
}

export const DASHER_TOKEN = "dasher-token"

export const PUPS = [
  {
    id: "3",
    active: true,
    displayName: "Card Blockers",
    ctaImageUrl: "https://trello.bluedashersoftware.com/static/docs-blockers-img.jpg",
    url: "https://trello.com/power-ups/656d0a018cb0ec048f1c5977",
    scheduleACallLink: "https://cal.com/541matt/trello",
    examples: [],
    description: 'Enhanced blocked, blocking, and milestone labels'
  },
  {
    id: "1",
    active: true,
    displayName: "Workspace Reports",
    ctaImageUrl: "https://trello.bluedashersoftware.com/static/docs-workspace-img.jpg",
    url: "https://trello.com/power-ups/62d1ae596c260a5422c5bff1/workspace-reports",
    scheduleACallLink:
      "https://cal.com/541matt/trello",
    examples: [
      {
        title: "Enable the Power-Up",
        link: "https://www.bluedashersoftware.com/work/enabling-the-power-up"
      },
      {
        title: "Configure a report",
        link: "https://www.bluedashersoftware.com/work/project-dashboard-settings"
      },
      {
        title: "Sort a report",
        link: "https://www.bluedashersoftware.com/work/sorting-project-dashboard"
      }
    ],
    description: 'Build dynamic table-based reports'
  },
  {
    id: "5",
    active: true,
    url: "https://trello.com/power-ups/65f5c6050e9a3183d652d603",
    displayName: "Mermaid Gantt",
    ctaImageUrl: "https://trello.bluedashersoftware.com/static/docs-gantt-img.jpg",
    scheduleACallLink: "https://cal.com/541matt/trello",
    examples: [],
    description: 'View your board as a Gantt chart with one click'
  },
  {
    id: "6",
    active: true,
    displayName: "Activity Calendar",
    url: "https://trello.com/power-ups/6609d3d70c987ef86e63cc59",
    ctaImageUrl: "https://trello.bluedashersoftware.com/static/docs-activity-calendar.jpg",
    scheduleACallLink: "https://cal.com/541matt/trello",
    examples: [],
    description: 'Identify busy times in your board with a heatmap style grid'
  },
  {
    id: "7",
    active: true,
    displayName: "Import to Trello (CSV-free)",
    ctaImageUrl: "https://trello.bluedashersoftware.com/static/docs-import-img.jpg",
    scheduleACallLink: "https://cal.com/541matt/trello",
    url: "https://trello.com/power-ups/6615872ac3e4359da8a7b1f4",
    examples: [],
    description: 'Import cards without ever leaving your Trello board'
  },
  {
    id: "8",
    active: true,
    displayName: "Quickbooks Time Tracking",
    ctaImageUrl: "https://trello.bluedashersoftware.com/static/docs-quickbooks-img.jpg",
    scheduleACallLink: "https://cal.com/541matt/trello",
    url: "https://trello.com/power-ups/6662176be2b1089c1e1762b9",
    examples: [],
    description: 'Add time entries to Quickbooks'
  }
]
