/* eslint-disable @typescript-eslint/no-explicit-any */
import { type Trello } from '../types/trello'
import { clearTrelloToken } from "@dasher-react/components"
import { clearDasherToken } from "@dasher-react/components"
import { PUP_ID } from '../config'

export function getRemoveData (
  t: Trello.PowerUp.IFrame,
): any {
  clearTrelloToken(t, PUP_ID)
  clearDasherToken(t)
}
