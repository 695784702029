import { type Trello } from '../types/trello'

export function getShowAuthorization (
  t: Trello.PowerUp.IFrame,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return t.modal({
    title: "Workspace Reports - Blue Dasher",
    url: './show-authorization.html',
    height: 400
  })
}
