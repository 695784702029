import React from 'react'

interface Props {
    onUpdateClick: () => void
    }

const UpdatePlanButton: React.FC<Props> = (props) => {
  return (
    <button
    onClick={props.onUpdateClick}
    className="font-bold text-white bg-blue-600 hover:bg-blue-500 rounded-sm py-2 px-4"
  >
    Update Payment Method
  </button>
  )
}

export default UpdatePlanButton