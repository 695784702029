/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios"
import prettyFormat from "pretty-format"
import { AxiosInstance } from "axios"
import camelcaseKeys from "camelcase-keys"
import decamelizeKeys from "decamelize-keys"
import { type Trello } from "./trello"
import {
  clearTrelloToken,
  getDasherToken,
  getTrelloToken,
  setDasherToken
} from "./helpers"
import { BoardConfig, QuickbooksMetadata, QuickbooksResponse, Subscription, View } from "./types"
export interface ApiReqOptions {
  method: string
  body?: unknown
}

interface TrelloRequestData {
  url: string
  method: string
  pup_id: string
  data?: any
}

export class ApiClient {
  baseURL: string
  axiosInstance: AxiosInstance
  pupId: string

  #getPupAuthToken = async (t: Trello.PowerUp.IFrame) => {
    const token = await getDasherToken(t)

    if (token) {
      return token
    }

    const trelloUserId = t.getContext().member

    const trelloToken = await getTrelloToken(t, this.pupId)
    const loadedToken = await this.#getAuthToken(t, trelloUserId, trelloToken)

    await setDasherToken(t, loadedToken)
    return loadedToken
  }

  makeTrelloRequest = async (t: Trello.PowerUp.IFrame, data: TrelloRequestData) => {
    return await this.#pupApiReq(t, `trello`, {
      method: "POST",
      body: data
    })
  }

  #getPupAuthHeader = async (t: Trello.PowerUp.IFrame) => {
    const token = await this.#getPupAuthToken(t)
    return { Authorization: `Token ${token}` }
  }

  #pupApiReq = async (
    t: Trello.PowerUp.IFrame,
    path: string,
    options: ApiReqOptions
  ): Promise<any> => {
    const authHeader = await this.#getPupAuthHeader(t)
    const response = await this.axiosInstance(path, {
      method: options.method,
      headers: authHeader,
      data: options.body,
      params: options.method === "GET" ? { pup_id: this.pupId } : undefined
    })
    return response?.data
  }

  #getAuthToken = async (
    t: Trello.PowerUp.IFrame,
    trello_user_id: string,
    trello_token: string
  ) => {
    const requestData: {
      trello_user_id: string
      pup_id: string
      organization_id: string
      trello_token: string
      board_id: string
    } = {
      trello_user_id,
      pup_id: this.pupId,
      organization_id: t.getContext().organization!,
      trello_token,
      board_id: t.getContext().board
    }

    try {
      const tokenResponse = await this.axiosInstance.post(`token`, requestData)

      const authToken = tokenResponse?.data?.token

      if (authToken) {
        return authToken
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        // We might have saved a bad trello token into local storage. Let's clear it out
        // And make the user re-auth
        clearTrelloToken(t, this.pupId)
      }

      console.error(prettyFormat(error))
      throw new Error("error getting blue dasher token")
    }
  }

  postSubscription = async (
    t: Trello.PowerUp.IFrame,
    orgId: string,
    data: Subscription
  ): Promise<Subscription> => {
    return await this.#pupApiReq(t, `organizations/${orgId}/subscriptions/`, {
      method: "POST",
      body: data
    })
  }

  updateSubscription = async (
    t: Trello.PowerUp.IFrame,
    id: string,
    data: Partial<Subscription>
  ): Promise<Subscription> => {
    return await this.#pupApiReq(t, `subscriptions/${id}`, {
      method: "PATCH",
      body: data
    })
  }

  getCurrentSubscription = async (
    t: Trello.PowerUp.IFrame,
    orgId: string
  ): Promise<Subscription> => {
    return await this.#pupApiReq(
      t,
      `organizations/${orgId}/currentsubscription/`,
      { method: "GET" }
    )
  }

  getSavedViews = async (t: Trello.PowerUp.IFrame, organizationId: string) => {
    return await this.#pupApiReq(
      t,
      `organizations/${organizationId}/savedviews/`,
      { method: "GET" }
    )
  }

  queryQuickbooksRecords = async (
    t: Trello.PowerUp.IFrame,
    query: string,
  ): Promise<QuickbooksResponse> => {
    return await this.#pupApiReq(t, `quickbooks/query`, {
      method: "POST",
      body: { query, }
    })
  }

  postQuickbooksTimeActivity = async (
    t: Trello.PowerUp.IFrame,
    data: any,
    del = false
  ) => {
    let url = `quickbooks/timeactivity`

    if (del) {
      url += "?operation=delete"
    }

    return await this.#pupApiReq(t, url, { method: "POST", body: data })
  }

  getSavedView = async (t: Trello.PowerUp.IFrame, viewId: string) => {
    return await this.#pupApiReq(t, `savedviews/${viewId}/`, { method: "GET" })
  }

  revokeQuickbooksAuth = async (t: Trello.PowerUp.IFrame) => {
    return await this.#pupApiReq(t, `intuit/revoke`, { method: "GET" })
  }

  getQuickbooksAuthUrl = async (t: Trello.PowerUp.IFrame) => {
    return await this.#pupApiReq(t, `intuit/auth`, { method: "GET" })
  }

  postSavedView = async (
    t: Trello.PowerUp.IFrame,
    view: Pick<View, Exclude<keyof View, "id" | "lastUpdated">>
  ) => {
    return await this.#pupApiReq(t, `savedviews/`, {
      method: "POST",
      body: view
    })
  }

  patchSavedView = async (
    t: Trello.PowerUp.IFrame,
    id: string,
    updatedItem: Partial<View>
  ) => {
    return await this.#pupApiReq(t, `savedviews/${id}/`, {
      method: "PATCH",
      body: updatedItem
    })
  }

  deleteSavedView = async (t: Trello.PowerUp.IFrame, viewId: string) => {
    return await this.#pupApiReq(t, `savedviews/${viewId}`, {
      method: "DELETE"
    })
  }

  getBoardConfigs = async (t: Trello.PowerUp.IFrame) => {
    return await this.#pupApiReq(t, `boardconfig/`, { method: "GET" })
  }

  postBoardConfig = async (t: Trello.PowerUp.IFrame, boardConfig: any) => {
    return await this.#pupApiReq(t, `boardconfig/`, {
      method: "POST",
      body: boardConfig
    })
  }

  patchBoardConfig = async (
    t: Trello.PowerUp.IFrame,
    id: string,
    updatedItem: Partial<BoardConfig>
  ) => {
    return await this.#pupApiReq(t, `boardconfig/${id}/`, {
      method: "PATCH",
      body: updatedItem
    })
  }

  postQuickbooksMetadata = async (t: Trello.PowerUp.IFrame, data: any) => {
    return await this.#pupApiReq(t, `quickbooks-timeactivity-metadata/`, {
      method: "POST",
      body: data
    })
  }

  deleteQuickbooksMetadata = async (t: Trello.PowerUp.IFrame, id: string) => {
    return await this.#pupApiReq(t, `quickbooks-timeactivity-metadata/${id}/`, {
      method: "DELETE"
    })
  }

  getQuickbooksMetadata = async (
    t: Trello.PowerUp.IFrame,
  ): Promise<QuickbooksMetadata[]> => {
    return await this.#pupApiReq(
      t,
      `quickbooks-timeactivity-metadata`,
      { method: "GET" }
    )
  }

  sendTrelloTokenToDasher = async (t: Trello.PowerUp.IFrame, token: string) => {
    // Even if they have a BD token, we need to make sure BD has their trello_token
    // They might already have it if this is a re-auth
    const trelloUserId = t.getContext().member
    await this.#getAuthToken(t, trelloUserId, token)
  }

  constructor(baseURL: string, pupId: string) {
    this.baseURL = baseURL
    this.pupId = pupId
    this.axiosInstance = axios.create({
      timeout: 30000,
      baseURL,
      headers: {
        "Content-Type": "application/json"
      }
    })
    this.axiosInstance.interceptors.response.use(
      function (response) {
        if (response.data) {
          response.data = camelcaseKeys(response.data, { deep: true })
        }
        return response
      },
      async function (error) {
        return await Promise.reject(error)
      }
    )
    this.axiosInstance.interceptors.request.use(
      function (config) {
        if (config.method === "patch" || config.method === "post") {
          config.data = decamelizeKeys(config.data)
        }
        return config
      },
      async function (error) {
        return await Promise.reject(error)
      }
    )
  }
}
