import dayjs from "dayjs"
import { DASHER_TOKEN, PRICE, TRELLO_TOKEN_KEY_MAP } from "../conifg"
import { Trello } from "./trello"
import { Subscription } from "./types"

export const getPlanMessage = (
  currentSubscription: Partial<Subscription>,
  pupDisplayName: string
) => {
  switch (currentSubscription.status) {
    case "active":
      return {
        status: `Your Blue Dasher subscription will renew for $${
          currentSubscription.unitPrice
        } on ${dayjs(currentSubscription.nextBillDate).format("MMM DD, YYYY")}`,
        cta: `Your workspace currently has access to the following Blue Dasher Power-Ups`
      }
    case "trialing":
      if (
        dayjs(currentSubscription.cancellationEffectiveDate).diff(
          dayjs(),
          "day"
        ) > 0
      ) {
        return {
          status: `Your trial will expire on ${dayjs(
            currentSubscription.cancellationEffectiveDate
          ).format(
            "MMM DD, YYYY"
          )}. Purchase a Blue Dasher subscription to continue using ${pupDisplayName}`,
          cta: `A $${PRICE}/month subscription unlocks all Blue Dasher Power-Ups for everyone in your workspace`
        }
      } else {
        return {
          status: `Your trial has expired. Purchase a Blue Dasher subscription to continue using ${pupDisplayName}`,
          cta: `A $${PRICE}/month subscription unlocks all Blue Dasher Power-Ups for everyone in your workspace`
        }
      }
    case "past_due":
      return {
        status: "Your subscription is past due",
        cta: `Update your payment method to maintain access to Blue Dasher Power-Ups`
      }
    case "deleted":
      if (
        dayjs(currentSubscription.cancellationEffectiveDate).diff(
          dayjs(),
          "day"
        ) > 0
      ) {
        return {
          status: `Your subscription to Blue Dasher will expire on ${dayjs(
            currentSubscription.cancellationEffectiveDate
          ).format("MMM DD, YYYY")}`,
          cta: `A $${PRICE}/month subscription unlocks all Blue Dasher Power-Ups for everyone in your workspace`
        }
      } else {
        return {
          status: `Your subscription or trial has expired. Subscribe to Blue Dasher to continue using ${pupDisplayName}`,
          cta: `A $${PRICE}/month subscription unlocks all Blue Dasher Power-Ups for everyone in your workspace.`
        }
      }

    default:
      return {
        status: "Your subscription or trial has expired",
        cta: `A $3/month subscription unlocks all Blue Dasher Power-Ups for everyone in your workspace.`,
      }
  }
}

export function getTrelloToken(t: Trello.PowerUp.IFrame, pupId: string) {
  return t.get("member", "private", TRELLO_TOKEN_KEY_MAP[pupId])
}

export function setTrelloToken(
  t: Trello.PowerUp.IFrame,
  token: string,
  pupId: string
) {
  return t.set("member", "private", TRELLO_TOKEN_KEY_MAP[pupId], token)
}

export function clearTrelloToken(t: Trello.PowerUp.IFrame, pupId: string) {
  return t.remove("member", "private", TRELLO_TOKEN_KEY_MAP[pupId])
}

export function getDasherToken(t: Trello.PowerUp.IFrame) {
  return t.get("member", "private", DASHER_TOKEN)
}

export function setDasherToken(t: Trello.PowerUp.IFrame, token: string) {
  return t.set("member", "private", DASHER_TOKEN, token)
}

export function clearDasherToken(t: Trello.PowerUp.IFrame) {
  return t.remove("member", "private", DASHER_TOKEN)
}

export const validateTrelloToken = (token: unknown): boolean => {
  if (typeof token !== "string" || !token || token.includes("error")) {
    return false
  } else {
    return true
  }
}
