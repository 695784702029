/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { type Trello } from "../types/trello"
import { type CapabilityProps } from "../types/power-up"
import { getAuthorizationStatus } from "../authorization-status/capability"
import { HELP_ACTION } from "../config"

export async function getBoardButton(
  t: Trello.PowerUp.IFrame,
  props: CapabilityProps
): Promise<Trello.PowerUp.BoardButtonCallback[]> {
  const { authorized } = await getAuthorizationStatus(t)

  return [
    {
      icon: {
        dark: props.baseUrl + props.icon.dark,
        light: props.baseUrl + props.icon.light
      },
      text: "Workspace Reports",
      callback: (t: Trello.PowerUp.IFrame) => {
        return t.modal({
          title: `Workspace Reports - Blue Dasher`,
          url: "./onboarding.html",
          height: !authorized ? 400 : 600,
          fullscreen: authorized ? true : false,
          actions: [HELP_ACTION]
        })
      },
      condition: "edit"
    }
  ]
}
