import { type Trello } from '../types/trello'

export async function getOnDisable (
  t: Trello.PowerUp.IFrame,
): Promise<void> {
  await t.alert({
    message: "Power-Up disabled. Thanks for trying Blue Dasher! If you need help, or have any feedback, please email us at support@bluedashersoftware.com",
    display: 'info',
    duration: 10
  })
}
