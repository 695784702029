import { type Trello } from './types/trello'
import { type CapabilityProps } from './types/power-up'
import { getOnDisable } from './on-disable/capability'
import { getOnEnable } from './on-enable/capability'
import { getBoardButton } from './board-button/capability'
import { getShowAuthorization } from './show-authorization/capability'
import { getAuthorizationStatus } from './authorization-status/capability'
import { getRemoveData } from './remove-data/capability'
import { POWERUP_DISPLAY_NAME } from './constants/constants'
import { getShowSettings } from './show-settings/capability'

const CAPABILITY_PROPS: CapabilityProps = {
  baseUrl: window.location.href.replace(/\/$/, ''),
  icon: {
    dark: '/static/new-icons/dark/bd-icon-dark.png',
    light: '/static/new-icons/color/bd-icon.png'
  },
  milestoneIcon: {
    dark: '/static/milestone-icon.svg',
    light: '/static/milestone-icon.svg'
  },
  blockingIcon: {
    dark: '/static/blocking-icon.svg',
    light: '/static/blocking-icon.svg'
  },
  blockedIcon: {
    dark: '/static/blocked-icon.svg',
    light: '/static/blocked-icon.svg'
  }
}
window.TrelloPowerUp.initialize(
  {
    'on-disable': async (t: Trello.PowerUp.IFrame) => { await getOnDisable(t) },
    'on-enable': async (t: Trello.PowerUp.IFrame) => { await getOnEnable(t) },
    'board-buttons': async (t: Trello.PowerUp.IFrame) =>
      await getBoardButton(t, CAPABILITY_PROPS),
    'show-authorization': (t: Trello.PowerUp.IFrame) =>
      getShowAuthorization(t),
    'authorization-status': async (t: Trello.PowerUp.IFrame) => {
      return await getAuthorizationStatus(t)
    },
    'show-settings': async (t: Trello.PowerUp.IFrame) => {
      return await getShowSettings(t)
    },
    'remove-data': (t: Trello.PowerUp.IFrame) =>
      getRemoveData(t)
  },
  {
    appKey: process.env.POWERUP_APP_KEY,
    appName: POWERUP_DISPLAY_NAME,
  }
)
